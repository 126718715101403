import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Helmet from "react-helmet";
import WhatsOnItem from "../components/whats-on-item";

class WhatsOn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { data } = this.props;

    return (
      <Layout>
        <Helmet>
          <title>What's On - Gracehouse Church Logan</title>
          <meta
            property="og:title"
            content="What's On - Gracehouse Church Logan"
          />
          <meta
            name="description"
            content="Discover our events. We run Sunday Services, Community Groups, Prayer Meetings and more."
          />
          <meta
            property="og:description"
            content="Discover our events. We run Sunday Services, Community Groups, Prayer Meetings and more."
          />
        </Helmet>
        <section className="updates-header">
          <div className="container">
            <h1>
              What's <span>On</span>
            </h1>
          </div>
        </section>
        <section className="generic-body">
          <div className="container">
            <div className="whats-on-list">
              {/* 7.30 - 8.30 */}
              <WhatsOnItem
                title="Sunday Service"
                url="https://www.youtube.com/channel/UC7ko9KyfJ5PS9eOuhjuWmbQ"
                extraTitle={"Church"}
                isYouTube={true}
                extraText={"Sun 9:30am"}
                times={[
                  {
                    startTime: {
                      dayOfWeek: 7,
                      hours: 9,
                      minutes: 30,
                    },
                    endTime: {
                      dayOfWeek: 7,
                      hours: 12,
                      minutes: 0,
                    },
                  },
                ]}
              />
              <div className="whats-on-item">
                <h2>Community Groups</h2>
                <p className="m-b-sm">
                    Contact us at <a class="generic-link" href='mailto:admin@gracehouse.com.au'>admin@gracehouse.com.au</a>
                  </p>
              </div>


              <WhatsOnItem
                title="Prayer Meetings"
                extraText={"Mon 7:00am, Wed 7:00am & Fri 7:00am"}
                url="https://us02web.zoom.us/j/81422634039?pwd=0v9cqfhu6ejTbhK47aHGqbXVrKstE6.1"
                extraTitle={"Church"}
                subtitle={"Meeting ID: 814 2263 4039. Password: Gracehouse"}
                times={[
                  {
                    name: "Prayer Meeting",
                    url: "https://us02web.zoom.us/j/81422634039?pwd=0v9cqfhu6ejTbhK47aHGqbXVrKstE6.1",
                    startTime: {
                      dayOfWeek: 1,
                      hours: 7,
                      minutes: 0,
                    },
                    endTime: {
                      dayOfWeek: 1,
                      hours: 7,
                      minutes: 30,
                    },
                  }, {
                    name: "Prayer Meeting",
                    url: "https://us02web.zoom.us/j/81422634039?pwd=0v9cqfhu6ejTbhK47aHGqbXVrKstE6.1",
                    startTime: {
                      dayOfWeek: 3,
                      hours: 7,
                      minutes: 0,
                    },
                    endTime: {
                      dayOfWeek: 3,
                      hours: 7,
                      minutes: 30,
                    },
                  }, {
                    name: "Prayer Meeting",
                    url: "https://us02web.zoom.us/j/81422634039?pwd=0v9cqfhu6ejTbhK47aHGqbXVrKstE6.1",
                    startTime: {
                      dayOfWeek: 5,
                      hours: 7,
                      minutes: 0,
                    },
                    endTime: {
                      dayOfWeek: 5,
                      hours: 7,
                      minutes: 30,
                    },
                  }
                ]}
              />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default WhatsOn;

export const pageQuery = graphql`
  query WhatsOnQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
